<template>
    <div id="service">
        <PageTitle :title="$t(`message.management.title`)" />
        <div class="services">
            <div class="service">
                <div class="service-content">
                    <h2>{{$t(`message.management.management`)}}</h2>
                    <p v-html="$t(`message.management.managementDescription`)"></p>
                    <router-link to="#contact" class="btn_started">
                        Get Started
                    </router-link>
                </div>
                <div v-bind:data-aos="isMobile ? 'fade-in' : 'fade-left'" class="service-image">
                    <img src="/images/service1.jpg" alt="Service Image" >
                </div>
            </div>
            <div class="service">
                <div class="service-content">
                    <h2>{{$t(`message.management.renting`)}}</h2>
                    <p v-html="$t(`message.management.rentingDescription`)"></p>
                    <router-link to="#contact" class="btn_started">
                        Get Started
                    </router-link>
                </div>
                <div v-bind:data-aos="isMobile ? 'fade-in' : 'fade-right'" class="service-image">
                    <img src="/images/service2.jpg" alt="Service Image" >
                </div>
            </div>
        </div>
        <hr class="seperator">
        <div class="properties-map">
            <h2>
                Properties We Manage
            </h2>
            <GmapMap
                :center="marker"
                :zoom="mapZoom"
                map-type-id="terrain"
                :style="'width: 100%; height:' + mapHeight  + ';'"
                :options="mapStyle"
                >
                <GmapInfoWindow
                    :position="infoWindowPos"
                    :opened="infoWinOpen"
                    :options="infoOptions"
                    @closeclick="infoWinOpen=false">
                    <div class="displayMap">
                        <router-link v-if="currentMapDisplayProperty.redirect" :to="`/property/${currentMapDisplayProperty.category}s/${currentMapDisplayProperty.slug}`">
                            <img :src="currentMapDisplayProperty.imgURL" :alt="currentMapDisplayProperty.slug" />
                        </router-link>
                        <img v-else :src="currentMapDisplayProperty.imgURL" :alt="currentMapDisplayProperty.slug" />
                        <div v-if="currentMapDisplayProperty.imgURL !== placeholderImage" class="logo-container">
                            <img src="/images/header-logo.png" alt="Header Logo">
                        </div>
                        <p>{{currentMapDisplayProperty.address}}</p>
                    </div>
                </GmapInfoWindow>
                <GmapMarker
                    v-for="(p, i) in propertiesWithMap"
                    :key="i + '_marker'"
                    :position="{lat: parseFloat(p.lat), lng: parseFloat(p.lng)}"
                    :clickable="true"
                    :icon="currentMidx === i ? selectedMarkerIcon : markerIcon"
                    @click="toggleInfoWindow(p, i)">
                </GmapMarker>
            </GmapMap>
        </div>
        <div class="service_list">
            <img class="logo-icon" src="/images/logo_icon.png" alt="Cerda Zein Logo Icon">
            <h2>{{$t(`message.management.managementServiceList.title`)}}</h2>
            <hr class="seperator" />
            <div class="service_list_container">
                <div data-aos="fade-up" class="service_category">
                    <ul>
                        <h3>{{$t(`message.management.managementServiceList.accountingServicesTitle`)}}</h3>
                        <li v-for="(t, i) in additionalServices" :key="i">
                            {{t}}
                        </li>
                    </ul>
                </div>
                <div data-aos="fade-up" class="service_category">
                    <ul>
                        <h3>{{$t(`message.management.managementServiceList.propertyMaintenanceTitle`)}}</h3>
                        <li v-for="(t, i) in propertyManagement" :key="i">
                            {{t}}
                        </li>
                    </ul>
                </div>
                <div data-aos="fade-up" class="service_category">
                    <ul>
                        <h3>{{$t(`message.management.managementServiceList.residentManagementTitle`)}}</h3>
                        <li v-for="(t, i) in residentManagement" :key="i">
                            {{t}}
                        </li>
                    </ul>
                </div>
                <div data-aos="fade-up" class="service_category">
                    <ul>
                        <h3>{{$t(`message.management.managementServiceList.rentalServicesTitle`)}}</h3>
                        <li class="margin_bottom">{{$t(`message.management.managementServiceList.additionalFee`)}}</li>
                        <li v-for="(t, i) in rentalServices" :key="i">
                            {{t}}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.services{
    display: flex;
    flex-direction: column;
    margin: 112px 0;
}
.service{
    display: flex;
    margin-bottom: 112px;
    align-items: center;
    flex-wrap: wrap;
}
.service-content{
    width: 38%;
    margin-left: 5%;
    margin-right: 80px;
    h2{
        border-bottom: 1px solid #000;
        display: inline-block;
        padding-bottom: 10px;
        font-size: 2.5rem;
        letter-spacing: 1.05;
        line-height: 3rem;
        font-weight: 400;
    }
    p{
        font-weight: 400;
        line-height: 2rem;
    }
    .btn_started{
        display: inline-block;
        color: #fff;
        background: #F26739;
        margin-top: 20px;
        padding: 15px 40px;
        text-decoration: none;
        text-transform: uppercase;
    }
}
.service:nth-child(even){
    flex-direction: row-reverse;
    .service-content{
        width: 38%;
        margin-left: 80px;
        margin-right: 5%;
    }
}
.service:nth-last-child(1){
    margin-bottom: 0;
}
.service-image{
    flex: 1;
    img{
        width: 100%;
    }
}
.seperator{
    width: 80%;
    margin: 0 auto;
}
h2{
    font-size: 2.75rem;
    line-height: 3.5rem;
    text-align: center;
    margin: 45px auto;
    letter-spacing: 1.01px;
    display: block;
}

.service_list{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #F1F1F1;
    padding: 60px 20px;
    margin: 27px;
}

.logo-icon{
    width: 77px;
}

.service_list_container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.service_category{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 40px;
    &:nth-child(1){
        margin-top: 125px;   
    }
    &:nth-child(2){
        margin-top: 125px;   
    }
}

.check-icon{
    display: inline-block;
    width: 22px;
}
h3{
    position: relative;
    display: inline-block;
    text-transform: uppercase;
    font-size: 1.75rem;
    line-height: 2.25rem;
    font-weight: 400;
    margin: 0;
    margin-bottom: 32px;
}
h3::before{
    position: absolute;
    height:22px;
    width:22px;
    left: -35px;
    top: 6px;
    content: "";
    background:url("/images/check.png") no-repeat 0 0;
    background-size: 22px 22px;
}

.margin_bottom{
    margin-bottom: 32px;
}

ul{
    list-style-type: none;
}
li{
    font-size: 1rem;
    line-height: 2rem;
    font-weight: 400;
    letter-spacing: 1.01;
}

.displayMap{
    text-align: center;
    .logo-container{
        img{
            display: block;
            margin: 6px auto;
            width: 92px;
        }
        border-bottom: 1px solid #ccc;
    }
    img{
        width: 100%;
        height: auto;
    }
    p{
        margin: 3px auto;
        color: #000;
        letter-spacing: 1.2px;
        font-weight: 400;
    }
}

@media (max-width: $mobile) {
    .services{
        margin: 79px 0;
    }
    .service{
        display: flex;
        margin-bottom: 79px;
        align-items: center;
        flex-wrap: wrap;
    }
    .service_list{
        margin: 27px 0;
    }
    .service-content{
        width: 100%;
        margin: 0 27px;
        text-align: center;
    }
    .service:nth-child(even){
        .service-content{
            width: 108%;
            margin: 0 27px;
            text-align: center;
        }
    }
    .service-image{
        margin-top: 30px;
    }
    .service_category{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-top: 40px;
    }
}
</style>

<script>
import PageTitle from "../components/PageTitle"

export default {
    name: "Service",
    components: {
        PageTitle
    },
    metaInfo: {
        title: 'Management Service',
        titleTemplate: '%s | Cerda-Zein',
        meta: [{
            name: 'description', 
            content: "Cerda-Zein"
        }]
    },
    data(){
        return{
            additionalServices:[
                "Collect all Rents",
                "Pay All Approved Expenses",
                "Disburse Funds Monthly",
                "Web Access to Accounting Statements",
                "Provide Digital Monthly Statements",
                "Prepare Budgets as Requested",
                "Schedule Rent Increases Accordingly"
            ],
            propertyManagement:[
                "Project Management",
                "Design Consulting",
                "Handle Maintenance Calls",
                "Secure Competitive Bids",
                "Provide Access to Vendors",
                "Supervise Vendors",
                "Conduct Property Surveys",
                "Schedule Preventative Maintenance"
            ],
            residentManagement:[
                "Coordinate Move In Surveys",
                "Coordinate Move Out Surveys",
                "Meet Resident(s) Onsite for Hand Off",
                "Collect & Return Security Deposits",
                "Provide Resident Manual",
                "Enforce All Rent Collection",
                "Enforce Property & HOA Rules",
                "Mediate Resident(s) Disputes",
                "Coordinate Appraiser Walkthroughs",
                "Serve Notices",
                "Evictions – Procure legal service"
            ],
            rentalServices:[
                "Determine Leasing Values",
                "Design Consulting",
                "Confirm Property/Unit is ready for Market",
                "Market Property/Unit for Lease",
                "Strong Online Marketing Presence",
                "Strong Social Media Presence",
                "Field Inquiries for Questions and Viewings",
                "Personally Show Available Units",
                "Screen All Applicants",
                "Screen All References",
                "Credit Screening",
                "Employment & Income Verification",
                "Negotiate Terms",
                "Prepare Lease and House Rules"
            ],
            title: "Properties",
            window: {
                width: 0,
                height: 0
            },
            markerIcon :"/images/marker_black.png",
            selectedMarkerIcon :"/images/marker_orange.png",
            marker: {
                lat: 37.763999,
                lng: -122.240771,
            },
            mapStyle: {
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
                styles: [
                    {
                        "featureType": "water",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#e9e9e9"
                            },
                            {
                                "lightness": 17
                            }
                        ]
                    },
                    {
                        "featureType": "landscape",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#f5f5f5"
                            },
                            {
                                "lightness": 20
                            }
                        ]
                    },
                    {
                        "featureType": "road.highway",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "color": "#ffffff"
                            },
                            {
                                "lightness": 17
                            }
                        ]
                    },
                    {
                        "featureType": "road.highway",
                        "elementType": "geometry.stroke",
                        "stylers": [
                            {
                                "color": "#ffffff"
                            },
                            {
                                "lightness": 29
                            },
                            {
                                "weight": 0.2
                            }
                        ]
                    },
                    {
                        "featureType": "road.arterial",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#ffffff"
                            },
                            {
                                "lightness": 18
                            }
                        ]
                    },
                    {
                        "featureType": "road.local",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#ffffff"
                            },
                            {
                                "lightness": 16
                            }
                        ]
                    },
                    {
                        "featureType": "poi",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#f5f5f5"
                            },
                            {
                                "lightness": 21
                            }
                        ]
                    },
                    {
                        "featureType": "poi.park",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#dedede"
                            },
                            {
                                "lightness": 21
                            }
                        ]
                    },
                    {
                        "elementType": "labels.text.stroke",
                        "stylers": [
                            {
                                "visibility": "on"
                            },
                            {
                                "color": "#ffffff"
                            },
                            {
                                "lightness": 16
                            }
                        ]
                    },
                    {
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "saturation": 36
                            },
                            {
                                "color": "#333333"
                            },
                            {
                                "lightness": 40
                            }
                        ]
                    },
                    {
                        "elementType": "labels.icon",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "transit",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#f2f2f2"
                            },
                            {
                                "lightness": 19
                            }
                        ]
                    },
                    {
                        "featureType": "administrative",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "color": "#fefefe"
                            },
                            {
                                "lightness": 20
                            }
                        ]
                    },
                    {
                        "featureType": "administrative",
                        "elementType": "geometry.stroke",
                        "stylers": [
                            {
                                "color": "#fefefe"
                            },
                            {
                                "lightness": 17
                            },
                            {
                                "weight": 1.2
                            }
                        ]
                    },
                    {
                        featureType: "poi",
                        stylers: [
                            { visibility: "off" }
                        ]
                    },
                    {
                        featureType: "transit",
                        stylers: [
                            { visibility: "off" }
                        ]
                    },
                    {
                        "featureType": "landscape",
                        "elementType": "labels",
                        "stylers": [
                            {"visibility": "off"}
                        ]
                    }
                ]
            },
            infoWindowPos: null,
            infoWinOpen: false,
            currentMidx: null,
            currentMapDisplayProperty:{
                category:"",
                imgURL:"",
                price:"",
                slug:"",
                address:"",
                redirect:false,
            },
            infoOptions: {
                //optional: offset infowindow so it visually sits nicely on top of our marker
                maxWidth: 200,
                pixelOffset: {
                    width: 0,
                    height: -45
                }
            },
            placeholderImage: "/images/coming-soon.jpg",
            apiUrl: 'https://strapi.cerda-zein.com'
        }
    },
    computed: {
        propertiesWithMap: function () {
            return this.$store.getters.properties.filter(
                function (property) {
                    return property.lat !== '' && property.lng !== '' && property.ServicePage;
                }
            )
        },
        mapHeight:function(){
            return (window.innerHeight - 48) + 'px';
        },
        mapZoom:function(){
            if(this.window.width < 576){
                return 11;
            }
            return 12;
        },
        isMobile:function(){
            return this.window.width <= 641;
        }
    },
    methods: {
        toggleInfoWindow: function(property, idx) {
            this.infoWindowPos = {lat: parseFloat(property.lat), lng: parseFloat(property.lng)}
            this.currentMapDisplayProperty.imgURL = property.FeaturedImage.data ? this.apiUrl+property.FeaturedImage.data.attributes.url : this.placeholderImage;
            this.currentMapDisplayProperty.redirect = property.ServicePageRedirect;
            const formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 0
            })
            let price = formatter.format(property.Price);
            this.currentMapDisplayProperty.price = price;
            this.currentMapDisplayProperty.slug = property.Slug;
            this.currentMapDisplayProperty.category = property.category;
            this.currentMapDisplayProperty.address = property.Address;
            //check if its the same marker that was selected if yes toggle
            if (this.currentMidx == idx) {
                this.infoWinOpen = !this.infoWinOpen;
            }
            //if different marker set infowindow to open and reset current marker index
            else {
                this.infoWinOpen = true;
                this.currentMidx = idx;
            }
        },
        handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        },
    },
    created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
}
</script>